<template>
  <div>
    <iframe
      :src="frame"
      width="100%"
      height="1200px"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import 'jspdf-autotable'

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontBody = ref(14)
    const frame = ref('')

    i18n.locale = route.value.query.lang == '2' ? 'en' : 'th'
    if (route.value.query.type == 'opd') {
      prints.printsCertDoctorOpd({
        opd_id: route.value.query.id,
        lang: i18n.locale,
      }).then(res => {
        dataTableList.value = res.orderdetail
        savePDF(res)
      })
    } else {
      prints.printsCertDoctor({
        cert_id: route.value.query.id.split(','),
        lang: i18n.locale,
      }).then(res => {
        dataTableList.value = res.orderdetail
        savePDF(res)
      })
    }
    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      })
      doc.setProperties({
        title: `Medical Certificate ${data.length == 1 ? `${data[0].customer_id} ${data[0].customer_fullname} - ${data[0].opd_date}` : ''}`,
      })
      doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'normal')
      doc.setFontSize(fontBody.value)

      for (let i = 0; i < data.length; i++) {
        doc.addImage(require('./CertDoctorEn.jpeg'), 'JPEG', 0, 0, 210, 297)
        doc.text(data[i].cert_code, 160, 32.4)
        doc.text(data[i].customer_fullname.replaceAll('null', ''), 54, 57)
        const address = doc.setFont('TH-Niramit', 'normal')
          .setFontSize(fontBody.value)
          .splitTextToSize(data[i].customer_address.replaceAll('null', ''), 104)
        if (address.length > 1) {
          doc.text(58, 62, address[0])
          doc.text(27, 67, address[1])
        } else {
          doc.text(58, 62, address[0])
        }

        // doc.text(data[i].customer_address.replaceAll('null', ''), 68, 48.7)
        const idCard = []
        if (data[i].customer_idcard && data[i].customer_idcard.length === 13) {
          for (let a = 0; a < data[i].customer_idcard.length; a++) {
            idCard.push(data[i].customer_idcard[a])
          }
          const idCardHeight = 77.6
          doc.text(idCard[0], 74, idCardHeight, null, null, 'center')
          doc.text(idCard[1], 81.7, idCardHeight, null, null, 'center')
          doc.text(idCard[2], 87.6, idCardHeight, null, null, 'center')
          doc.text(idCard[3], 93.3, idCardHeight, null, null, 'center')
          doc.text(idCard[4], 99.3, idCardHeight, null, null, 'center')
          doc.text(idCard[5], 106.8, idCardHeight, null, null, 'center')
          doc.text(idCard[6], 112.7, idCardHeight, null, null, 'center')
          doc.text(idCard[7], 118.6, idCardHeight, null, null, 'center')
          doc.text(idCard[8], 124.2, idCardHeight, null, null, 'center')
          doc.text(idCard[9], 130, idCardHeight, null, null, 'center')
          doc.text(idCard[10], 137.7, idCardHeight, null, null, 'center')
          doc.text(idCard[11], 143.6, idCardHeight, null, null, 'center')
          doc.text(idCard[12], 151.2, idCardHeight, null, null, 'center')
        }
        const heightDate = 132.3
        doc.text(data[i].opd_date_d, 110, heightDate)
        doc.text(data[i].opd_date_m, 141, heightDate)
        doc.text(data[i].opd_date_y, 167, heightDate)
        const heightDate2 = 158.8
        doc.text(data[i].shop_name, 61, heightDate2)
        doc.text(data[i].opd_date_d, 109, heightDate2)
        doc.text(data[i].opd_date_m, 137, heightDate2)
        doc.text(data[i].opd_date_y, 163, heightDate2)
        doc.text(data[i].user_fullname, 37, 163.5)
        doc.text(data[i].user_license || '', 150, 163.5)

        // doc.text(data[i].shop_name, 127, 152.8)

        doc.text(`${data[i].shop_address} ${data[i].shop_district} ${data[i].shop_amphoe} ${data[i].shop_province} ${data[i].shop_zipcode}`, 70, 168.8)

        doc.text(data[i].customer_fullname.replaceAll('null', ''), 67, 173.6)
        const heightDate25 = 178.7
        doc.text(data[i].opd_date_d, 43, heightDate25)
        doc.text(data[i].opd_date_m, 69, heightDate25)
        doc.text(data[i].opd_date_y, 93, heightDate25)
        const heightDate3 = 183.7
        doc.text(data[i].opd_w || '', 39, heightDate3)
        doc.text(data[i].opd_h || '', 69, heightDate3)
        doc.text(data[i].opd_bp || '', 112, heightDate3)
        doc.text(data[i].opd_p || '', 148, heightDate3)
        const commment = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(data[i].cert_comment, 104)
        if (commment.length > 1) {
          doc.text(90, 239.4, commment[0])
          let totalArr = ''
          commment.forEach((item, index) => {
            if (index >= 1) {
              totalArr += item
            }
          })
          const commment2 = doc.setFont('TH-Niramit', 'normal').setFontSize(fontBody.value).splitTextToSize(totalArr, 157)
          let startComment = 244.6
          commment2.forEach(element => {
            doc.text(27, startComment, element)
            startComment += 4.8
          })
        } else {
          doc.text(90, 240.7, commment)
        }

        doc.text(`(${data[i].user_fullname})`, 148, 264.7, null, null, 'center')
        doc.addPage(1)
      }

      doc.deletePage(data.length + 1)
      frame.value = doc.output('datauristring', { filename: 'hello world' })
    }
    const go = () => {
      window.open(frame.value, '_blank')
    }

    return { savePDF, frame, go }
  },
}
</script>
